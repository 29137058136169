import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCartItems } from 'app/store/auth/cartsSlice';
import { getProducts } from 'app/main/apps/e-commerce/store/productsSlice';
import { formatDateString } from 'app/main/apps/common/AuraFunctions';
import format from 'date-fns/format';
import { showMessage } from 'app/store/fuse/messageSlice';
import { getOrders } from './ordersSlice';


export const getPrintProductionData = createAsyncThunk(
  'DashboardApp/widgets/getPrintProductionData',
  async (params) => {
    const response = await axios.post(`/api/widgetOrderItemData`, null, { params });
    const data = await response.data.shipping_label;
    return data;
  }
);

export const getOrder = createAsyncThunk('eCommerceApp/order/getOrder', async (params) => {
  const response = await axios.get(`/api/orders/${params.orderId}`);
  const data = await response.data.order;
  return data === undefined ? null : getInitValues(data);
});

export const getCommunicationHistories = createAsyncThunk('eCommerceApp/order/getCommunicationHistories', async ({ id, params }) => {
  const response = await axios.get(`/api/communicationNotes/${id}`, { params });
  const data = await response.data;
  return data
});

export const getRelated = createAsyncThunk('eCommerceApp/order/getRelatedDetails', async (id) => {
  const response = await axios.get(`api/orders/${id}`);
  const data = await response.data.order.child_orders;
  return data
})

export const getOrderDetails = createAsyncThunk(
  'eCommerceApp/order/getOrderDetails',
  async ({ id, params, signal = null }, { dispatch, getState }) => {
    const config = signal ? { params, signal } : { params };
    const response = await axios.get(`/api/orders/${id}`, config);
    const data = await response.data.order;
    return data ? getInitValues(data) : data;
  }
);

export const downloadOrderPdf = createAsyncThunk(
  'eCommerceApp/order/downloadOrderPdf',
  async ({ id, stage, orderNo }, { dispatch, getState }) => {
    try {
      const response = await axios.get(`/api/downloadOrder`, {
        params: { order_id: id },
        responseType: 'blob', // Specify response type as blob for file download
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Set a filename (you can customize this based on the API response or logic)
      link.setAttribute('download', `${stage}_${orderNo}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return true; // Indicate success
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      throw error;
    }
  }
);


export const getCustomOrder = createAsyncThunk(
  'eCommerceApp/order/getCustomOrder',
  async ({ id }, { dispatch, getState }) => {
    const response = await axios.get(`/api/getCustomOrder/${id}`);
    const data = await response.data.order;
    return data
  }
);

export const getOrderItemDiscount = createAsyncThunk(
  'eCommerceApp/order/getOrderItemDiscount',
  async ({ cid, pid, stage }, { dispatch, getState }) => {
    const response = await axios.get(`/api/productDiscount`, { params: { cid, pid, stage } });
    const data = await response.data;
    return data;
  }
);

export const getDefaultWeight = createAsyncThunk(
  'eCommerceApp/order/getDefaultWeight',
  async () => {
    const response = await axios.get('/api/setting', { params: { category: 'default_weight_adjustment' } })
    const data = await response.data;
    return data
  }
)

export const requestOrder = createAsyncThunk(
  'eCommerceApp/order/requestOrder',
  async (params, { dispatch }) => {
    const response = await axios.post('/api/addOrders', params);
    const data = await response.data;
    dispatch(getCartItems());
    dispatch(getProducts());
    return data;
  }
);

export const saveOrder = createAsyncThunk(
  'eCommerceApp/order/saveOrder',
  async (orderData, { dispatch, getState }) => {
    dispatch(setLoading());
    const { order } = getState().eCommerceApp;
    let endPoint = '/api/orders';
    let params = {}
    // TODO why do we need to send to separate endpoint?
    if ((orderData.isQuote || orderData.stage === 'quote') && !orderData.isQuoteEdit) {
      endPoint = '/api/addQuote';
      if (orderData.id) {
        endPoint = orderData.isQuote ? '/api/requestForQuote' : '/api/updateRfq'
        if (!orderData.isQuote) {
          params = {
            action_name:
              orderData.saveType && orderData.saveType === "moveQuoteToRfq"
                ? "MAKE_IT_IN_REVIEW"
                : "OUT_FOR_QUOTE_APPROVAL",
          };
        }
      }
    }

    // if (orderData.stage === 'rfq') {
    //   endPoint = '/api/requestForQuote';
    //   if (orderData.id) {
    //     endPoint = 'api/updateRfq';
    //   }
    // }
    if (orderData.stage === 'rfq') {
      const isConverToQuote = orderData.orderType === 'convert-to-quote'
      orderData = {
        ...orderData,
        status: {
          ...orderData.status,
          category: isConverToQuote ? 'quote_status' : 'rfq_status'
        },
        stage: isConverToQuote ? 'quote' : orderData.stage
      }
    }

    let response;
    if (orderData.id) {
      response = await axios.put(`${endPoint}/${orderData.id}`, {
        ...orderData,
      }, { params });
    } else {
      response = await axios.post(endPoint, {
        ...order,
        ...orderData,
      });
    }

    const data = await response.data.order;
    dispatch(resetLoading());
    return getInitValues(data);
  }
);

export const chooseSupplierForArtWork = createAsyncThunk(
  'eCommerceApp/order/chooseSupplierForArtWork',
  async (orderData, { dispatch, getState }) => {
    dispatch(setLoading());
    const response = await axios.put(`/api/requestForQuote/${orderData.id}`, { ...orderData })
    const data = await response.data.order;
    dispatch(resetLoading());
    return getInitValues(data);
  }
);

export const placeReOrder = createAsyncThunk(
  'eCommerceApp/order/reOrder',
  async (reOrderData, { dispatch, getState }) => {
    await axios.post('/api/orders', reOrderData);
  }
);

export const updateOrder = createAsyncThunk(
  'eCommerceApp/order/updateOrder',
  async (inputData, { dispatch }) => {
    let params = {}
    if (inputData?.is_complete) {
      params = {
        is_complete: true
      }
    }
    const response = await axios.put(`/api/orders/${inputData.id}`, inputData, { params });
    const data = await response.data.order
    return data;
  }
);

// TODO performaction api might be common (need to check) and should be placed in some other slice
export const performAction = createAsyncThunk(
  'eCommerceApp/order/performAction',
  async (payload, { dispatch }) => {
    dispatch(setPerformingAction());
    const response = await axios.put(`/api/performAction`, payload);
    const data = await response.data.order ? response.data.order : response.data;
    dispatch(resetPerformingAction());
    return data;
  }
);

export const getContactInfo = createAsyncThunk('eCommerceApp/order/getContactInfo', async () => {
  const response = await axios.get(`/api/contactInfo`);
  const data = await response.data;
  return data;
});

export const getQuotedSuppliers = createAsyncThunk('eCommerceApp/order/getQuotedSuppliers', async ({ id, params }, { dispatch }) => {
  dispatch(setQuotedSuppliersListLoading(true))
  const response = await axios.get(`/api/viewSupplier/${id}`, { params });
  dispatch(setQuotedSuppliersListLoading(false))
  const data = await response.data;
  return data
});

export const getInvoiceDetails = createAsyncThunk('eCommerceApp/order/getInvoiceDetails', async (params) => {
  const response = await axios.get(`/api/invoice`, { params });
  const data = await response.data;
  return data
});

export const getBriefInvoiceDetails = createAsyncThunk('eCommerceApp/order/getBriefInvoiceDetails', async ({ id }) => {
  const response = await axios.get(`/api/getInvoiceDetails/${id}`);
  const data = await response.data;
  return data
});

export const getShippingLabelData = createAsyncThunk('eCommerceApp/order/getShippingLabelData', async (params) => {
  const response = await axios.get(`/api/shipmentLabel`, { params });
  const data = await response.data;
  return data
});

export const convertToOrder = createAsyncThunk(
  'eCommerceApp/order/convertToOrder',
  async (params, { dispatch }) => {
    dispatch(setLoading());
    await axios
      .put(`api/quote/${params.id}`, params)
      .then(() => {
        dispatch(
          showMessage({
            message: 'Quote has been converted to order',
            type: 'success'
          })
        );
      });
  }
);

export const updateShipmentApproval = createAsyncThunk(
  'eCommerceApp/order/updateShipmentApproval',
  async (params, { dispatch }) => {
    dispatch(setLoading());
    await axios
      .put(`api/shipment/${params.id}`, params)
      .then(() => {
        dispatch(
          showMessage({
            message: `Shipment has been ${params.is_approve ? 'approved' : 'rejected'}`,
            type: 'success'
          })
        );
      });
  }
);

export const getAdvancePayment = createAsyncThunk(
  'eCommerceApp/order/getAdvancePayment',
  async (id) => {
    const response = await axios.get(`/api/getAdvancePayment/${id}`);
    const data = await response.data;
    return data
  }
);

export const addAdvancePayment = createAsyncThunk(
  'eCommerceApp/order/addAdvancePayment',
  async (payload) => {
    const response = await axios.put(`/api/advancePayment/${payload.id}`, payload);
    const data = await response.data;
    return data;
  }
);

const initialValue = {
  props: {
    open: false,
  },
  data: null,
  param: null,
  dashboard: false,
};

export const initState = {
  loading: false,
  performingAction: false,
  disableOrderRequest: false,
  data: null,
  includeOrderItem: null,
  orderDialog: initialValue,
  confirmInvoiceDialog: initialValue,
  viewOrderDialog: initialValue,
  chooseSupplierDialog: initialValue,
  createSupplierOrderDialog: initialValue,
  quotedSuppliersList: {},
  quotedSuppliersListLoading: false
};

function getBalanceAmount(data) {
  return data.balance_amount ? data.balance_amount : data.amount;
}

export function getInitValues(data, type) {
  return {
    id: data && data.id ? data.id : null,
    order_no: data && data.order_no ? data.order_no : '',
    name: data && data.name ? data.name : '',
    legacy_id: data && data.legacy_id ? data.legacy_id : '',
    customer_id: data && data.customer_id ? data.customer_id : '',
    customer: data && data.customer ? data.customer : null,
    supplier: data && data.supplier ? data.supplier : null,
    suppliers: data && data.suppliers ? data.suppliers : null,
    selected_customer: data && data.customer ? { ...data.customer } : null,
    sales_person: data && data?.sales_person,
    date:
      data && data.date
        ? formatDateString(data.date, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    eta_date: data && data.eta_date ? formatDateString(data.eta_date, 'yyyy-MM-dd') : '',
    quantity: data && data.quantity ? data.quantity : '',
    customer_provider_number: data && data.customer_provider_number ? data.customer_provider_number : '',
    comments: data && data.comments ? data.comments : '',
    decedent: data && data.decedent ? data.decedent : '',
    amount: data && data.amount ? data.amount : '',
    order_details: data && data.order_details ? data.order_details : [],
    status_id: data && data.status_id ? data.status_id : null,
    status: data && data.status ? data.status : null,
    status_change_time: data && data.status_change_time ? data.status_change_time : null,
    status_histories: data && data.status_histories ? data.status_histories : [],
    note_histories: data && data.note_histories ? data.note_histories : [],
    documents: data && data.uploadedFile ? data.uploadedFile : [],
    payment_details: data && data.payment_details ? data.payment_details : [],
    draft_invoices_pre_tax_total:
      data && data.draft_invoices_pre_tax_total ? data.draft_invoices_pre_tax_total : '',
    finalized_invoices_pre_tax_total:
      data && data.finalized_invoices_pre_tax_total ? data.finalized_invoices_pre_tax_total : '',
    all_invoices_pre_tax_total:
      data && data.all_invoices_pre_tax_total ? data.all_invoices_pre_tax_total : '',
    shipping_cost: data && data.shipping_cost ? data.shipping_cost : '',
    all_invoices_balance: data && data.all_invoices_balance ? data.all_invoices_balance : '',
    paid_amount: data && data.paid_amount ? data.paid_amount : 0,
    balance_amount: data ? getBalanceAmount(data) : 0,
    payment_status: data && data.payment_status ? data.payment_status : '',
    reference_decedent: data && data.reference_decedent ? data.reference_decedent : '',
    decedents: data && data.decedents ? data.decedents : '',
    ship_to: data && data.ship_to ? data.ship_to : '',
    client_contract_number: data && data.client_contract_number ? data.client_contract_number : '',
    note: data && data.note ? data.note : '',
    tags: data && data.tags ? data.tags : '',
    order_type: data && data.order_type ? { name: data.order_type } : null,
    location: data?.location ? data.location : '',
    weight: data?.order_details && data?.order_details[0]?.weight ? data?.order_details[0]?.weight : '',
    length: data?.order_details && data?.order_details[0]?.length ? data?.order_details[0]?.length : '',
    width: data?.order_details && data?.order_details[0]?.width ? data?.order_details[0]?.width : '',
    height: data?.order_details && data?.order_details[0]?.height ? data?.order_details[0]?.height : '',
    stone_color: data?.order_details && data?.order_details[0]?.product ? data?.order_details[0]?.product?.stone_color : null,
    finish: data?.order_details && data?.order_details[0]?.finish ? data?.order_details[0]?.finish : '',
    polish: data?.order_details && data?.order_details[0]?.polish ? data?.order_details[0]?.polish : '',
    type: data?.order_details && data?.order_details[0]?.product?.type ? data?.order_details[0]?.product?.type : '',
    sub_type:
      data?.order_details && data?.order_details[0]?.product?.sub_type
        ? data?.order_details[0]?.product?.sub_type
        : '',
    product: data?.order_details && data?.order_details[0]?.product ? data?.order_details[0]?.product : '',
    remarks: data && data.remarks ? data.remarks : '',
    reference: data && data.reference ? data.reference : '',
    requested_date:
      data && data.eta_date ? formatDateString(data.eta_date, 'yyyy-MM-dd') : '',
    is_drawing_approval: data?.is_drawing_approval,
    is_proof_approval: data?.is_proof_approval,
    location_for_regular: data && data.location_for_regular ? data.location_for_regular : '',
    quantity_history: data?.quantity_history || [],
    customer_collaborators: data?.customer_collaborators?.length > 0 ? data?.customer_collaborators?.map((item) => ({
      ...item,
      name: item?.email,
      nameOpt: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`, // Fallback to empty string if null or undefined
    })) : [],
    include_shipping_cost: data ? data?.include_shipping_cost : true,
    confirmed_order: data ? data?.confirmed_order : false,
    advance_payments: data?.advance_payments
  };
}

const orderSlice = createSlice({
  name: 'eCommerceApp/order',
  initialState: initState,
  reducers: {
    resetOrder: (state, action) => {
      state.data = null;
      state.includeOrderItem = null;
    },
    addOrderItem: (state, action) => {
      state.includeOrderItem = action.payload;
    },
    resetOrderItem: (state, action) => {
      state.includeOrderItem = null;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    setPerformingAction: (state, action) => {
      state.performingAction = true;
    },
    resetPerformingAction: (state, action) => {
      state.performingAction = false;
    },
    setQuotedSuppliersListLoading: (state, action) => {
      state.quotedSuppliersListLoading = action.payload;
    },
    newOrder: {
      reducer: (state, action) => {
        state.data = action.payload;
      },
      prepare: (type) => ({
        payload: getInitValues(null, type),
      }),
    },
    openOrderDialog: (state, action) => {
      state.orderDialog = {
        props: {
          open: true,
        },
        completeOrderData: action.payload.completeOrderData,
        data: action.payload.data,
        param: action.payload.param,
        stage: action.payload.stage,
      };
    },
    closeOrderDialog: (state, action) => {
      state.orderDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
    openConfirmInvoiceDialog: (state, action) => {
      state.confirmInvoiceDialog = {
        props: {
          open: true
        },
        completeOrderData: action.payload.completeOrderData,
        data: action.payload.data,
        param: action.payload.param,
        stage: action.payload.stage,
      }
    },
    closeConfirmInvoiceDialog: (state, action) => {
      state.confirmInvoiceDialog = {
        props: {
          open: false
        },
        data: null,
        param: null,
      }
    },
    openViewOrderDialog: (state, action) => {
      state.viewOrderDialog = {
        props: {
          open: true,
        },
        stage: action.payload.stage,
        data: action.payload.data,
        param: action.payload.param,
        completeOrderData: action.payload.completeOrderData,
        dashboard: false,
      };
    },
    closeViewOrderDialog: (state, action) => {
      state.viewOrderDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
        dashboard: false,
      };
    },
    openChooseSupplierDialog: (state, action) => {
      state.chooseSupplierDialog = {
        props: {
          open: true,
        },
        param: action.payload.param,
        completeOrderData: action.payload.completeOrderData,
        name: action.payload.name,
        status: action.payload.status,
        stage: action.payload.stage
      };
    },
    closeChooseSupplierDialog: (state) => {
      state.chooseSupplierDialog = {
        props: {
          open: false,
        },
        param: null,
      };
      resetOrder()
    },
    openSupplierShippingDialog: (state, action) => {
      state.createSupplierOrderDialog = {
        props: {
          open: true,
        },
        param: action.payload.param,
        type: action.payload.type,
        completeOrderData: action.payload.completeOrderData,
        data: action.payload.data,
        // Added optionalData to avoid causing issues in the existing code
        optionalData: action.payload.optionalData
      };
    },
    closeSupplierShippingDialog: (state) => {
      state.createSupplierOrderDialog = {
        props: {
          open: false,
        },
        param: null,
        optionalData: null,
      };
      resetOrder()
    },
    updateQuotedSuppliersList: (state, action) => {
      const {
        selectedIndex,
        selectedOrder,
        field,
        value,
        updateVas
      } = action.payload;
      if (updateVas) {
        state.quotedSuppliersList.orders[selectedIndex].order_details[selectedOrder].vas[field] = value
      }
      else if(field === "unit_price") {
        state.quotedSuppliersList.orders[selectedIndex].order_details[selectedOrder].product[field] = value;
      }
      else if(field === "margin") {
        state.quotedSuppliersList.orders[selectedIndex].order_details[selectedOrder][field] = value;
        state.quotedSuppliersList.orders[selectedIndex].order_details[selectedOrder].product[field] = value;
      }
      else {
        state.quotedSuppliersList.orders[selectedIndex].order_details[selectedOrder][field] = value;
      }
    },
    resetCommunicationHistories: (state, action) => {
      state.communicationHistories = [];
    },
  },
  extraReducers: {
    [getOrder.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getCommunicationHistories.fulfilled]: (state, action) => {
      state.communicationHistories = action.payload.notes?.[0] || []
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [saveOrder.fulfilled]: (state, action) => {
      state.loading = false;
      // As of now we are redirecting to another page after successful saveOrder
      // So Removed setting data to avoid displaying nan values
      // state.data = action.payload;
    },
    [saveOrder.rejected]: (state, action) => {
      state.loading = false;
    },
    [requestOrder.pending]: (state, action) => {
      state.disableOrderRequest = true;
    },
    [requestOrder.fulfilled]: (state, action) => {
      state.disableOrderRequest = false;
    },
    [requestOrder.rejected]: (state, action) => {
      state.disableOrderRequest = false;
    },
    [convertToOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [convertToOrder.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateShipmentApproval.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateShipmentApproval.rejected]: (state) => {
      state.loading = false;
    },
    [getQuotedSuppliers.fulfilled]: (state, action) => {
      state.quotedSuppliersList = action.payload;
    },
    [getQuotedSuppliers.pending]: (state) => {
      state.quotedSuppliersList = {}
    },
  },
});

export const {
  resetOrder,
  newOrder,
  openOrderDialog,
  closeOrderDialog,
  openConfirmInvoiceDialog,
  closeConfirmInvoiceDialog,
  openViewOrderDialog,
  closeViewOrderDialog,
  addOrderItem,
  resetOrderItem,
  setLoading,
  resetLoading,
  setPerformingAction,
  resetPerformingAction,
  setQuotedSuppliersListLoading,
  setQuotedSuppliersList,
  openChooseSupplierDialog,
  closeChooseSupplierDialog,
  openSupplierShippingDialog,
  closeSupplierShippingDialog,
  updateQuotedSuppliersList,
  resetCommunicationHistories
} = orderSlice.actions;

export default orderSlice.reducer;