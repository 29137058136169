
import { Fragment, useEffect, useRef, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import _ from '@lodash';
import FuseUtils from '@fuse/utils';
import { authPermissions } from 'app/auth';
import { useSelector, useDispatch } from 'react-redux';
import { AuraSimpleTableHead } from 'app/main/apps/common/AuraSimpleTable';
import { getStatuses } from 'app/main/dashboards/main/store/widgetsSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  Checkbox,
  Collapse,
  Icon,
  IconButton,
  Radio,
  TextField,
  Switch,
  Box,
  Button,
  Tooltip,
  Chip,
  Alert, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { openDialog, closeDialog as fuseCloseDialog } from 'app/store/fuse/dialogSlice';
import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import OrdersRowActions from 'app/main/apps/e-commerce/orders/OrdersRowActions';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import Vas from 'app/main/apps/e-commerce/order/Dialogs/Vas';
import { getSuppliers, quoteToOrder, quoteToSupplier, receiveSupplierShipmentOrder } from 'app/main/apps/e-commerce/store/rfqsSlice';
import { getOrders, getPaymentHistory } from 'app/main/apps/e-commerce/store/ordersSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { formatDateString, getDiscountAmtFromPercentage, isCustomer, isSupplier, formatter } from 'app/main/apps/common/AuraFunctions';
import ImageRenderer from 'app/main/apps/common/ImageRenderer';
import ProductDetailBody, { ProductTableHeader } from 'app/main/apps/e-commerce/order/Dialogs/ProductDetailTable';
import { receiveShipment } from 'app/main/apps/backoffice/calendar/store/eventsSlice';
import { closeViewOrderDialog } from '../store/widgetsSlice';
import { closeViewOrderDialog as orderCloseViewOrderDialog, placeReOrder, resetOrder } from '../../../apps/e-commerce/store/orderSlice';
import OrderHeader from '../../../apps/common/OrderHeader';
import AuraBaseDialog from '../../../apps/common/AuraBaseDialog';
import OpenOrdersRowAction from '../widgets/OpenOrdersRowAction';
import ImageContent from './ImageContent';
import PrintableColumns from '../print/PrintableColumns';
import ApprovalContent from '../../../apps/e-commerce/order/Dialogs/ApprovalContent';

export default function ViewOrderDialog(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ user }) => user);
  const isManage = currentUser && FuseUtils.hasPermission(authPermissions.manage, currentUser.role);
  const performingAction = useSelector(({ eCommerceApp }) => eCommerceApp.order.performingAction);
  const [open, setOpen] = useState({});
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [isChanged, setIschnaged] = useState(false);
  const [map, setMap] = useState('documents');
  const [selectedIndex, setSelectedIndex] = useState()
  const [openProductDetails, setopenProductDetails] = useState([])
  const [approvalDialog, setApprovalDialog] = useState({})
  const [attachMessage, setAttachMessage] = useState(false);
  const [checked, setChecked] = useState(false)
  const [selectedImages, setSelectedImages] = useState({})
  const [reasonOption, setReasonOption] = useState([])
  const [loading, setLoading] = useState(false)

  const buildSelectedDrawing = (selectedImagesData, fullDetails) => {
    if (fullDetails?.length === 0) return []
    return fullDetails.map((detail, index) => {
      const selectedDocs = selectedImagesData[index] || [];
      const selectedDocIds = selectedDocs.map(doc => doc.id);
      const filteredDocuments = detail?.drawing_documents?.filter(doc => selectedDocIds.includes(doc.id));
      return {
        order_detail_id: detail.id,
        file: filteredDocuments
      };
    });
  };

  function validateDrawingDocuments(selectedImagesData, fullDetails) {
    if (fullDetails?.length === 0) return []
    const errors = [];
    let isAnyDrawingDocumentsFound = false;
    const arrayIndex = [];
    fullDetails?.forEach((detail, index) => {
      const isDrawingDocumentsFound = detail?.drawing_documents?.length > 0;
      if(!isAnyDrawingDocumentsFound) {
        isAnyDrawingDocumentsFound = isDrawingDocumentsFound;
      }
      if(isDrawingDocumentsFound) {
        const selectedDocs = selectedImagesData[index] || [];
        const selectedDocIds = selectedDocs.map(doc => doc.id);
        const filteredDocuments = detail?.drawing_documents?.filter(doc => selectedDocIds.includes(doc.id));
        const noDocumentSelected = !filteredDocuments || (filteredDocuments && filteredDocuments?.length === 0);
        if (noDocumentSelected) {
          errors.push({
            order_detail: detail,
            row_index: index,
          })
          arrayIndex.push(index+1);
        }
      }
    });
    if(isAnyDrawingDocumentsFound && errors.length > 0) {
      return {errors, errorRows: arrayIndex}
    }
    return {};
  }

  const showDrawingConfirmation = (arrayIndex) => {
    dispatch(
      openDialog({
        children: (
          <>
            <DialogTitle id="alert-dialog-title">
              Drawing/Proof Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                No drawing/proof selected for order item {_.join(arrayIndex, ", ")}.
                Do you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  dispatch(fuseCloseDialog());
                  setLoading(false);
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  dispatch(fuseCloseDialog());
                  sendQuoteRequestToSupplier();
                }}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </>
        ),
      })
    );
  };

  const buildRecieveOrderPayload = (orderId, formValues) => {
    const payload = {
      order_id: orderId,
      order_details: []
    }
    formValues?.order_details?.forEach((item) => {
      if (item?.isSelected) {
        payload.order_details.push({
          id: item?.order_detail_id,
          quantity: item?.newQty,
          notes: _.isObject(item?.notes) ? item?.notes?.inputValue || item?.notes?.name : item?.notes
        })
      }
    })
    return payload
  }


  const handleSelectedImages = (media, index) => {
    setSelectedImages(prevState => {
      // Initialize the state for the given index if it's undefined
      const currentSelected = prevState[index] || [];

      // Check if the media is already selected
      const isAlreadySelected = currentSelected.some(item => item.id === media.id);

      if (isAlreadySelected) {
        // If it's already selected, remove it from the state
        return {
          ...prevState,
          [index]: currentSelected.filter(item => item.id !== media.id)
        };
      }
      // If it's not selected, add it to the state
      return {
        ...prevState,
        [index]: [...currentSelected, media]
      };
    });
  }

  const viewOrderDialog = useSelector(({ mainDashboardApp, eCommerceApp }) => {
    if (props.isDashboard) {
      return mainDashboardApp?.widgets?.viewOrderDialog;
    }
    return eCommerceApp?.order?.viewOrderDialog;
  });

  useEffect(() => {
    if (viewOrderDialog.data) {
      viewOrderDialog.data.forEach((orderDetail, index) => {
        setOpen((prev) => ({ ...prev, [index]: true }))
      })
    }
  }, [viewOrderDialog.data])

  const isVasPrice = viewOrderDialog.param?.is_vas_order
  const isCommunicationTab = viewOrderDialog.param?.communicationTab

  const [dialogParam, setDialogParam] = useState({
    type: 'View',
    name: 'viewOrder',
    title: 'View Order',
    hideDialogActions: true,
  });
  const [isPrinting, setIsPrinting] = useState(false);
  const { suppliersList, previousSuppliers, suppliersListLoading } = useSelector(
    (state) => state.eCommerceApp.rfqs
  );
  const quotedSuppliersList = useSelector((state) => state.eCommerceApp?.order?.quotedSuppliersList?.orders);
  const quotedSuppliersListLoading = useSelector((state) => state.eCommerceApp?.order?.quotedSuppliersListLoading);
  const paymentHistoryList = useSelector((state) => state.eCommerceApp?.orders?.paymentHistory);
  const selectedProducts = useSelector(({ eCommerceApp }) => eCommerceApp.products.selectedProducts);
  const shipemntInfo = useSelector(({ mainDashboardApp }) => mainDashboardApp?.widgets?.shipmentInfo)

  const methods = useForm({
    mode: 'onChange',
  });
  const { setValue, watch, control, formState, getValues, reset, handleSubmit } = methods;
  const { errors } = formState;
  const printRef = useRef();
  const promiseResolveRef = useRef(null);

  function disableSubmitButton() {
    if (getType('receive shipment'))
      return false;
    if (getType('create supplier order'))
      return !(isChanged && selectedSuppliers.length && selectedIndex);
    if (viewOrderDialog?.param?.onSubmit)
      return loading;
    return loading || !(isChanged && selectedSuppliers.length);
  }

  useEffect(() => {
    reset({ suppliers: [] })
    setSelectedSuppliers([]);
    setIschnaged(false);
    setSelectedIndex()
    setSelectedImages({})
    setAttachMessage(false)
  }, [viewOrderDialog.props.open]);

  useEffect(() => {
    if (getType('Receive Order')) {
      dispatch(getStatuses('receive_status')).then((data) => setReasonOption(data.payload))
      reset({
        order_details: viewOrderDialog.data?.map((item) => {
          return {
            newQty: item.quantity,
            order_detail_id: item.id,
            quantity: item.quantity,
            isSelected: true
          }
        })
      })
    }
  }, [reset, viewOrderDialog.props.open])

  useEffect(() => {
    if (viewOrderDialog.param?.resetForm) {
      reset(viewOrderDialog.param.resetFormData)
    }
  }, [viewOrderDialog.param?.resetForm])

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const { completeOrderData, dashboard } = viewOrderDialog;
  const getType = name => viewOrderDialog.param?.name === name
  const image = watch('documents');
  const attachmentsProps = {
    type: 'document',
    category: 'document',
    allowAdd: true,
    addTo: 'rfq',
    allowDelete: true,
    acceptedTypes: 'image/*,.pdf',
    changeStyle: true,
  };

  const hideDiscountField = () => {
    if (isSupplier(currentUser.role) || (viewOrderDialog?.param?.isSupplierInvoice === true)) return true
    // if (viewOrderDialog?.data?.every(order => order.discount === null || order.discount === 0)) return true
    return ['rfq', 'rfd', 'completed', 'shipment', 'in-production'].includes(viewOrderDialog?.stage);
  }

  const isColumnHidden = (columnName) => {
    const { stage } = viewOrderDialog;
    switch (columnName) {
      case 'Order No':
        return !['invoice', 'in-production'].includes(stage);
      case 'Weight':
      case 'Total Weight':
        return ['rfq', 'invoice', 'rfd', 'completed'].includes(stage);
      case 'Price':
      case 'Amount':
        return ['rfq', 'rfd', 'completed'].includes(stage);
      case 'Tariff':
      case 'Margin':
        if (isCustomer(currentUser.role)) return true
        if (!isCustomer(currentUser.role)) return true
        return !['quote'].includes(stage)
      case 'Discount':
        if (isSupplier(currentUser.role) || (viewOrderDialog?.param?.isSupplierInvoice === true)) return true
        return ['rfd', 'completed', 'shipment', 'in-production'].includes(stage);
      case 'Comments':
        if (!isCustomer(currentUser.role)) return true
        return !['rfq'].includes(stage)
      default:
        return false;
    }
  };

  const tableHeaders = [
    { name: '' },
    { name: 'No ' },
    { name: 'Order No', hide: !['invoice', 'in-production'].includes(viewOrderDialog.stage) },
    { name: 'LEN', hide: showNamefield()},
    { name: 'THK', hide: showNamefield() },
    { name: 'HGT', hide: showNamefield() },
    { name: 'Color', hide: showNamefield() },
    { name: 'Shape', hide: showNamefield() },
    { name: 'Polish', hide: showNamefield() },
    { name: 'Customer Name', hide: !showCustomerNamefield() },
    { name: 'Product Name', hide: !showNamefield() },
    { name: 'Sub Type' },
    { name: 'Order Type', hide: !showOrderTypefield() },
    { name: 'Quantity' },
    { name: 'Reference No', hide: viewOrderDialog.stage === 'in-production' },
    { name: 'Decedent', hide: viewOrderDialog.stage === 'in-production' },
    { name: 'Weight', hide: isColumnHidden('Weight') },
    { name: 'Tariff', hide: isColumnHidden('Tariff') },
    { name: 'Margin', hide: isColumnHidden('Margin') },
    { name: 'Price', hide: isColumnHidden('Price') || isVasPrice },
    { name: 'Total Weight', hide: isColumnHidden('Total Weight') },
    { name: 'Discount', hide: hideDiscountField()},
    { name: 'Comments', hide: isColumnHidden('Comments') },
    { name: 'Amount', hide: isColumnHidden('Amount') },
    { name: 'Box Number', hide: !['in-production', 'fulfilled', 'invoice'].includes(viewOrderDialog.stage) },

    // { name: 'Amount' },
  ];

  // TODO This is a temporary fix that need to be removed once invoice response has been added
  const oldTableHeaders = [
    { name: '' },
    { name: 'No' },
    { name: 'Order No', hide: !['invoice', 'in-production'].includes(viewOrderDialog.stage) },
    { name: 'Reference No' },
    { name: 'Decedent' },
    { name: 'Description' },
    { name: 'Weight', hide: viewOrderDialog.stage === 'invoice' },
    { name: 'Quantity' },
    { name: 'Price' },
    { name: 'Discount', hide: hideDiscountField()},
    { name: 'Amount' },
    { name: 'Box Number', hide: ['invoice'].includes(viewOrderDialog.stage) && !isSupplier(currentUser.role) },
  ];

  const hasImages = (type) => viewOrderDialog?.data?.some(d => d[type])
  if (!hasImages('vas') && !hasImages('documents')) {
    tableHeaders.shift();
  }

  const supplierHeaders = [{ name: '' }, { name: 'Name' }, { name: 'Email' }, { name: 'Phone' }];
  const chooseSupplierHeaders = [{ name: '' }, { name: 'Name' }, { name: 'Email' }];
  const supplierOrderHeaders = [{ name: '' }, { name: 'Name' }, { name: 'Email' }, { name: 'Phone' }, { name: '' }];
  const shipmentInfoHeaders = [{ name: '' }, { name: 'Order No' }, { name: 'ETA' }, { name: 'Quantity' }, { name: 'Weight' }]
  const receiveOrderHeaders = [
    { name: '' },
    { name: 'No ' },
    { name: 'Product Name' },
    { name: 'Reference No', hide: viewOrderDialog.stage === 'in-production' },
    { name: 'Decedent', hide: viewOrderDialog.stage === 'in-production' },
    { name: 'Weight', hide: isColumnHidden('Weight') },
    { name: 'Price', hide: isColumnHidden('Price') || isVasPrice },
    { name: 'Total Weight', hide: isColumnHidden('Total Weight') },
    { name: 'Discount', hide: hideDiscountField() },
    { name: 'Amount', hide: isColumnHidden('Amount') },
    { name: 'Quantity' },
    { name: 'Reason' },
    // { name: 'Notes' },
  ]
  // TODO This is a temporary fix that need to be removed once invoice response has been added
  let headers = tableHeaders;
  if (viewOrderDialog.stage === 'invoice') headers = oldTableHeaders
  if (getType('send to supplier')) headers = isCommunicationTab ? chooseSupplierHeaders : tableHeaders;
  else if (getType('create supplier order')) headers = supplierOrderHeaders;
  else if (getType('Send for Approval')) headers = shipmentInfoHeaders;
  else if (getType('Receive Order')) headers = receiveOrderHeaders
  else if (getType('payment')) headers = [{ name: 'No' }, { name: 'Payment Type' }, { name: 'Amount Received' }, { name: 'Notes' }, { name: 'Date' }];

  const getList = () => {
    if (getType('send to supplier')) {
      const filterIds = new Set(previousSuppliers.map(item => item.id));

      // Filter out the objects from the originalArray where the id is in the filterIds set
      return suppliersList.filter(item => !filterIds.has(item.id));
    }
    if (getType('Send for Approval')) {
      return shipemntInfo
    }
    return quotedSuppliersList
  }

  const list = getList();
  const addNote = ['Send for Proof Approval', 'Send for Drawing Approval', 'Approve Drawing', 'Request Revision for Drawing',
    'Reject Drawing', 'Approve Proof', 'Request Revision for Proof', 'Reject', 'Reject Proof', 'REJECT_QUOTE']

  /**
   * Close Dialog
   */
  function closeDialog(event, reason) {
    setLoading(false);
    if (dashboard) {
      dispatch(closeViewOrderDialog());
    } else {
      dispatch(orderCloseViewOrderDialog());
    }
    setopenProductDetails([])
    resetOrder();
  }

  function isDialogName(name) {
    return dialogParam.name === name;
  }

  const onPrint =
    useReactToPrint({
      content: () => printRef.current,
      onBeforeGetContent: () => {
        return new Promise((resolve) => {
          promiseResolveRef.current = resolve;
          setIsPrinting(true);
        });
      },
      onAfterPrint: () => {
        // Reset the Promise resolve so we can print again
        promiseResolveRef.current = null;
        setIsPrinting(false);
      },
      pageStyle: '',
      documentTitle: `${viewOrderDialog.stage}.pdf`,
    });


  const headerData = {
    enable: true,
    component: OrderHeader,
    data: {
      order: completeOrderData,
      hideAmount: getType('Receive Order') || ['invoice'].includes(viewOrderDialog.stage),
      hideQuantity: ['invoice'].includes(viewOrderDialog.stage),
      hideDate: ['invoice', 'rfq', 'rfd', 'completed'].includes(viewOrderDialog.stage),
      hideCustomer: viewOrderDialog.param?.actionName === 'RECEIVE_ORDER' || ['in-production'].includes(viewOrderDialog.stage) || isSupplier(currentUser.role) && viewOrderDialog.stage === 'invoice' || viewOrderDialog?.param?.name === 'payment',
      showTotalWeight: ['order', 'quote', 'approval', 'in-production'].includes(viewOrderDialog.stage),
      showPrintAction: getType('viewOrder'),
      showDownloadAction: false,
      onPrint,
      stage: viewOrderDialog.stage,
      overRideData: getType('create supplier order') ? {
        amount: _.sumBy(quotedSuppliersList, (supplier) =>
          _.sumBy(supplier.order_details, (item) => item.quantity * item.unit_price)
        )
      } : null,
      showSupplierName: ['order'].includes(viewOrderDialog.stage) || (viewOrderDialog?.param?.name === 'payment' && dialogParam.isSupplierInvoice),
      // TODO Once we got the api we need to enable this
      // onDownload: () => { },
      isPrinting,
      showContainerNo: viewOrderDialog.stage === 'in-production' && isSupplier(currentUser.role),
      hideCTNAndCompany: viewOrderDialog.stage === 'shipment' && ['staff', 'admin', 'sales'].includes(currentUser.role),
      isCommunicationTab
    },
  };

  useEffect(() => {
    setValue('documents', viewOrderDialog?.data?.[0]?.uploadedFile);
    if (viewOrderDialog.param?.showAttachment) {
      viewOrderDialog?.data?.forEach((item, index) => {
        if (item?.drawing_documents?.length > 0 || item?.proof_documents?.length > 0) {
          setValue(`order_details.${index}.${viewOrderDialog.param.is_proof_approval ? 'proof_documents' : 'drawing_documents'}`, viewOrderDialog.param.is_proof_approval ? item.proof_documents : item.drawing_documents)
        }
      });
    }
  }, [viewOrderDialog]);

  useEffect(() => {
    if (viewOrderDialog?.param?.title === 'Send to Supplier for Vas Price') {
      dispatch(getSuppliers({ suppliers: [], params: { type: 'art/design,vas' } }))
    }

  }, [viewOrderDialog.props.open])

  useEffect(() => {
    if (list?.length && getType('create supplier order')) {
      setSelectedSuppliers([{ id: list[0].supplier.id }]);
      setIschnaged(true)
      setSelectedIndex(list[0].id)
    }
  }, [list, getType('create supplier order')]);

  useEffect(() => {
    if (getType('payment') && viewOrderDialog.props.open && completeOrderData?.id)
      dispatch(getPaymentHistory(completeOrderData?.id))
  }, [getType('payment'), viewOrderDialog])

  const handleSelect = (orderDetail, selected) => {
    if (selected) setSelectedSuppliers((prev) => [...prev, { id: orderDetail.id }]);
    else setSelectedSuppliers((prev) => prev.filter(({ id }) => id !== orderDetail.id));
    setIschnaged(true);
  };

  const handleSendToSupplier = () => {
    if (viewOrderDialog.param?.type === 'reOrder' && selectedSuppliers.length)
      return reOrder()
    if (isCommunicationTab) {
      closeDialog();
      return viewOrderDialog.param?.loadOrdersFun(selectedSuppliers)
    }
    return sendQuoteRequestToSupplier();
  };

  const sendQuoteRequestToSupplier = () => {
    const imageMap = buildSelectedDrawing(selectedImages, viewOrderDialog.data);
    if(loading === false) {
      setLoading(true);
    }
    return dispatch(
        quoteToSupplier({ id: viewOrderDialog.param.id, payload: { action_name: viewOrderDialog.param.action, suppliers: selectedSuppliers, image_map: imageMap } })
      )
      .unwrap()
      .then(() => {
        closeDialog();
        if (props.isDashboard && viewOrderDialog.param?.loadOrdersFun)
          viewOrderDialog.param?.loadOrdersFun();
        else
          dispatch(getOrders({ params: { stage: viewOrderDialog.stage } }))
        dispatch(showMessage({ message: viewOrderDialog.stage === 'rfq' ? 'Sent to supplier' : 'Order has been sent to supplier successfully', type: 'success' }));
      });
  }

  const reOrder = () => {
    const payload = {
      supplier: selectedSuppliers[0],
      order_details: selectedProducts
    }
    dispatch(placeReOrder(payload))
      .then((res) => {
        closeDialog();
        if (!res.error) {
          dispatch(showMessage({ message: 'Success', type: 'success' }));
          props.loadProducts()
        }
      });
  }


  const handleCreateSupplierOrder = () => {
    if (selectedIndex) {
      return dispatch(
        quoteToOrder({ id: viewOrderDialog.param.id, payload: { action_name: viewOrderDialog.param.action, is_approval_type: checked, supplier: selectedSuppliers[0] }, params: { qid: selectedIndex } })
      ).then((res) => {
        closeDialog();
        if (!res.error) {
          dispatch(showMessage({ message: 'Supplier order created', type: 'success' }));
          if (props.isDashboard && viewOrderDialog.param?.loadOrdersFun)
            viewOrderDialog.param?.loadOrdersFun();
          else
            dispatch(getOrders({ params: { stage: 'order' } }))
        }
      });
    }
    setLoading(false);
    return dispatch(showMessage({ message: 'Please Choose Price', type: 'error' }));
  }

  const additionalButton = viewOrderDialog?.param?.showApproveButton &&
    <Button
      variant="contained"
      color="secondary"
      type="submit"
      onClick={
        handleSubmit(() => handleFormSubmit("ORDER_IN_QUEUE_FOR_VAS_PROOF"))
      }
    >
      Approve
    </Button>


  useEffect(() => {
    if (getValues()?.order_details?.length) {
      setAttachMessage(false)
    }
  }, [getValues()?.order_details?.length])


  const handleFormSubmit = (type) => {
    const { onSubmit, sendFormValue, showAttachment, title, documentApproval } = viewOrderDialog?.param || {};
    const approvalPayload = [];
    if(getType('receive shipment') || !getType('send to supplier') || (getType('send to supplier') && (viewOrderDialog.param?.type !== 'View' || isCommunicationTab))) {
      setLoading(true);
    }

    if (getType('receive shipment')) {
      if(completeOrderData?.shipment_id) {
        dispatch(receiveShipment({ id: completeOrderData?.shipment_id }))
        .unwrap()
        .then((res) => {
          closeDialog();
          if (!res.error) {
            dispatch(showMessage({ message: 'Shipment received successfully', type: 'success' }));
            dispatch(getOrders({ params: { stage: 'container' } }))
          }
        });
        return;
      }
      setLoading(false);
      return;
    }

    let hasError
    if (showAttachment && title !== 'Move to Production') {
      viewOrderDialog?.data?.forEach((item, index) => {
        const documentData = getValues()?.order_details?.[index]
        const isDocumentExist = viewOrderDialog.param.is_proof_approval ? documentData?.proof_documents?.length > 0 : documentData?.drawing_documents?.length > 0
        const documentKey = viewOrderDialog.param.is_proof_approval ? documentData?.new_proof_documents?.length > 0 : documentData?.new_drawing_documents?.length > 0
        if (!isDocumentExist && !documentKey && title !== 'Send for Order Approval') {
          hasError = true;
        }
      });
    }
    if (documentApproval) {
      viewOrderDialog?.data?.forEach((item, index) => {
        const documentData = getValues()?.order_details?.[index]
        const order = {
          id: item.id,
          is_approval_type: item.is_approval_type,
          documents: []
        };

        if (item.is_approval_type && documentData?.approval_documents === undefined) {
          hasError = true
        }

        if (documentData?.approval_documents?.length > 0) {
          order.documents = documentData?.approval_documents?.map((doc => ({ id: doc.id })));
        }

        approvalPayload.push(order)
      });
    }
    if (hasError) {
      setAttachMessage(true);
      setLoading(false);
      return
    }
    if (getType('Receive Order')) {
      const values = getValues();
      const receiveOrderPayload = buildRecieveOrderPayload(completeOrderData?.id, values)
      dispatch(
        receiveSupplierShipmentOrder({ payload: receiveOrderPayload })
      )
        .unwrap()
        .then(() => {
          closeDialog();
          if (props.isDashboard && viewOrderDialog.param?.loadOrdersFun)
            viewOrderDialog.param?.loadOrdersFun();
          else
            dispatch(getOrders({ params: { stage: viewOrderDialog.stage } }))
          dispatch(showMessage({ message: "Success" }));
        });
      return
    }
    if (onSubmit) {
      const values = getValues();
      if (values?.order_details) {
        values.order_details = values.order_details?.map((item) => {
          if (item?.vas) {
            item.vas = JSON.stringify(item.vas);
          }
          return item;
        });
      }
      const noteValue = sendFormValue ? values : values.note;
      // const document = values?.new_documents?.map(item => ({ id: item.id }))
      onSubmit(noteValue, type, approvalPayload)?.finally(() => {
        closeDialog();
      });
    }
    else if (getType('send to supplier') && viewOrderDialog.param?.type === 'View' && !isCommunicationTab) {
      const validationResponse = validateDrawingDocuments(selectedImages, viewOrderDialog.data);
      if(validationResponse && validationResponse.errorRows && validationResponse.errorRows.length > 0) {
        showDrawingConfirmation(validationResponse.errorRows);
      } else {
        sendQuoteRequestToSupplier();
      }
    }
    else if (getType('send to supplier')) {
      handleSendToSupplier();
    }
    else {
      handleCreateSupplierOrder();
    }
  }

  const handleOpenProductDetials = (index, isOpened) => {
    setopenProductDetails(prev => {
      if (isOpened)
        return prev.filter(id => id !== index)
      return [...prev, index]
    })
  }

  const getMessage = (index, isProofApproval) => {
    const documentData = getValues()?.order_details?.[index]
    const documentKey = isProofApproval ? (documentData?.new_proof_documents || documentData?.proof_documents) : (documentData?.new_drawing_documents || documentData?.drawing_documents)
    if (!documentKey) {
      return 'Document needs to be attached'
    }
    return ''
  }

  const supplierCols = (
    <>
      {list?.map((data, index) => {
        const orderDetail = (getType('create supplier order')) ? data.supplier : data;
        const isOpened = openProductDetails.includes(index)
        let drawingDocuments = [];
        const documentData = getValues()?.order_details?.[index]
        const approvalDocuments = documentData?.approval_documents || []

        if (approvalDocuments?.length > 0) {
          drawingDocuments = approvalDocuments
        }
        return (
          <Fragment key={index}>
            <TableRow>
              <TableCell scope="row" padding='checkbox'>
                {getType('send to supplier') && viewOrderDialog.param?.type !== 'reOrder' ?
                  <Checkbox
                    disabled={previousSuppliers.some(({ id }) => orderDetail.id === id)}
                    onClick={(e) => handleSelect(orderDetail, e.target.checked)}
                    checked={previousSuppliers.some(({ id }) => orderDetail.id === id) || selectedSuppliers.some(({ id }) => orderDetail.id === id)}
                  />
                  : null}
              </TableCell>
              {!getType('Send for Approval') && (
                <>
                  <TableCell scope="row">
                    <Typography className="">{orderDetail?.name}</Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography className="">{orderDetail?.email}</Typography>
                  </TableCell>
                  {
                    !isCommunicationTab && (
                      <TableCell scope="row">
                        <Typography className="">{orderDetail.primary_phone}</Typography>
                      </TableCell>
                    )
                  }
                </>
              )}
              {getType('Send for Approval') && (
                <>

                  <TableCell scope="row">
                    <Typography className="">{orderDetail.order_no}</Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography className="">{formatDateString(orderDetail.eta_date)}</Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography className="">{orderDetail.quantity}</Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography className=""> {orderDetail.weight}{orderDetail.weight !== null && ' lbs'}</Typography>
                  </TableCell>
                </>
              )}
              {
                viewOrderDialog.param?.documentApproval &&
                <TableCell scope="row">
                  <Tooltip title="Add Documents">
                    <FuseSvgIcon
                      className="text-48 cursor-pointer"
                      size={24}
                      color="action"
                      onClick={() => {
                        setApprovalDialog({
                          type: 'addAttachments',
                          title: 'Drawing Approval',
                          open: true,
                          attachmentProps: {
                            newAttachmentsType: `order_details.${index}.approval_documents`,

                          }
                        })
                      }}
                    >
                      material-solid:attach_file
                    </FuseSvgIcon>

                  </Tooltip>
                  <Typography className='text-red'>{attachMessage && viewOrderDialog?.data?.[index].is_approval_type && getMessage(index, viewOrderDialog?.data?.[index].is_approval_type)}</Typography>
                </TableCell>

              }
              {(getType('create supplier order') || getType('Send for Approval')) && (
                <>
                  {/* Hided this action and added this logic above while choosing the supplier */}
                  {/* <TableCell scope="row">
                    <div className='flex items-center'>
                      {Number(data.amount) ? <Switch
                        size="small"
                        checked={data.id === selectedIndex}
                        onClick={(e) => setSelectedIndex(prev => prev === data.id ? undefined : data.id)}
                      /> : null}
                      <Typography className="">$ {data.amount}</Typography>
                    </div>
                  </TableCell> */}
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpenProductDetials(index, isOpened)}
                    >
                      {!isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                </>)}


            </TableRow>

            {(getType('create supplier order') || getType('Send for Approval')) &&
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={getType('Send for Approval') ? isOpened : !isOpened} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 5 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Product Details
                      </Typography>
                      <Table size="small" aria-label="details">
                        <ProductTableHeader hideCalculativePice type='create supplier order' hideForType={{ vasOrder: data?.vas_order_type, title: viewOrderDialog?.param?.title }} />
                        <ProductDetailBody showDocuments={getType('Send for Approval')} tableData={data.order_details} type='create supplier order' hideForType={{ vasOrder: data?.vas_order_type, title: viewOrderDialog?.param?.title }} />
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>}
            {
              getType('Send for Approval') && drawingDocuments?.length ? (
                <TableRow>
                  <TableCell colSpan={6}>

                    <Typography variant="h6" className="mt-6 mb-10">
                      Approval Documents
                    </Typography>
                    <ImageRenderer attachments={drawingDocuments} />
                  </TableCell>
                </TableRow>
              ) : <></>
            }
          </Fragment>
        )
      })}
    </>
  );

  const paymentCols = (
    <>
      {paymentHistoryList?.map((data, index) => {
        return (
          <TableRow key={index}>
            <TableCell scope="row">
              {index + 1}
            </TableCell>
            <TableCell scope="row">
              <Typography className="">{data.payment_type}</Typography>
            </TableCell>
            <TableCell scope="row">
              <Typography className="">{data.paid_amount !== null && ''}{formatter.format(data.paid_amount)}</Typography>
            </TableCell>
            <TableCell scope="row">
              {
                data?.description ? (
                  <Tooltip title={data.description}>
                    <Typography style={{ whiteSpace: "nowrap" }}>{`${data?.description?.slice(0, 80)}...`}</Typography>
                  </Tooltip>
                ) : '-'
              }
            </TableCell>
            <TableCell scope="row">
              <Typography className="">{formatDateString(data.date)}</Typography>
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )

  const getCols = () => {
    if (getType('create supplier order') || getType('Send for Approval'))
      return supplierCols
    if (getType('payment'))
      return paymentCols

    return null
  }

  let combineAction
  combineAction = isCustomer(currentUser.role) && ['order', 'quote'].includes(viewOrderDialog?.stage)

  if (isManage) {
    if (viewOrderDialog?.stage === 'quote' && viewOrderDialog?.param?.name === "Review Quote") {
      combineAction = true
    }
  }

  const getComponent = () => {
    if (props.isDashboard) {
      return (
        <OpenOrdersRowAction
          order={completeOrderData}
          stage={viewOrderDialog.stage}
          orderId={completeOrderData.id ? completeOrderData.id : completeOrderData.shipment_id}
          isManage={isManage}
          orderData={completeOrderData}
          popupButtons
          loadOrders={viewOrderDialog.param.loadOrders}
          closeDialog={closeDialog}
          isDashboard={props.isDashboard}
          type={props.type}
          approveProof={combineAction ? viewOrderDialog?.param?.showDialogActions : undefined}
          approveNote={getValues()?.note}
          listData={viewOrderDialog?.data}
          selectedImages={selectedImages}
          popupData= {{ name: viewOrderDialog?.param?.name}}
        />
      )
    }
    return (
      <OrdersRowActions
        row={{ original: { ...completeOrderData } }}
        cell={{ column: { userManage: isManage } }}
        closeDialog={closeDialog}
        popupButtons
        approveProof={combineAction ? viewOrderDialog?.param?.showDialogActions : undefined}
        approveNote={getValues()?.note}
        loadOrders={viewOrderDialog.param.loadOrders}
        listData={viewOrderDialog?.data}
        selectedImages={selectedImages}
        popupData= {{ name: viewOrderDialog?.param?.name}}
      />
    )
  }

  const vasCustomzation = () => {
    return (
      <div>
        {viewOrderDialog?.data?.[0]?.vas?.stoneUrl &&
          <div  >
            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" >

              <defs>
                <mask id="removeWhiteMask">
                  <rect width="100%" height="100%" fill="white" />
                  <rect x="75" y="75" width="250" height="195" fill="transparent" > </rect>
                </mask>
              </defs>

              <image xlinkHref={`data:${viewOrderDialog?.data?.[0]?.vas?.stoneUrl?.data['Content-Type']};base64,${viewOrderDialog?.data?.[0]?.vas?.stoneUrl?.data}`} width="100%" height="100%" mask="url(#removeWhiteMask)" />
              {viewOrderDialog?.data?.[0]?.vas.images?.map((media, ind) => {
                return <svg xmlns="http://www.w3.org/2000/svg">
                  <image
                    xlinkHref={`data:${media['Content-Type']};base64,${media?.data}`}
                    x={media.x}
                    y={media.y}
                    width={media?.width}
                    height={media?.height}
                    mask="url(#removeWhiteMask)"
                  />
                </svg>
              }
              )}

              {viewOrderDialog?.data?.[0]?.vas.text?.map((labelItem, labelindex) => <text
                textAnchor={labelItem?.align}
                id={labelItem.id}
                x={labelItem.x}
                y={labelItem.y}
                dy=".35em"
                fill="white"
                fontFamily={labelItem.fontGroup}
              >
                {labelItem.value}
              </text>
              )
              }
            </svg>
          </div>
        }
      </div>
    )
  }

  function isShippingCost(orderDetail) {
    return orderDetail?.name === "Shipping Cost" && orderDetail?.order_id === null;
  }

  function showCustomerNamefield() {
    return viewOrderDialog?.param?.pageType === "container";
  }

  function showOrderTypefield() {
    return viewOrderDialog?.param?.pageType === "container";
  }

  function showNamefield() {
    return viewOrderDialog?.param?.pageType === "container";
  }

  // TODO This is a temporary fix that need to be removed once invoice response has been added
  const oldColumns = (orderDetail) => (<>
    <TableCell scope="row">
      <Typography className="">{orderDetail.customer_provider_number}</Typography>
    </TableCell>
    <TableCell scope="row">
      <Typography className="">{orderDetail.decedents}</Typography>
    </TableCell>
    <TableCell scope="row">
      <Typography className="">{orderDetail.name}</Typography>
    </TableCell>

    {((['order', 'quote'].includes(viewOrderDialog.stage)) || getType('Receive Order')) && (
      <TableCell scope="row">
        <Typography className="whitespace-nowrap">{orderDetail.weight}{orderDetail.weight !== null && ' lbs'}</Typography>
      </TableCell>
    )}
    <TableCell scope="row">
      <Typography className="">{orderDetail.quantity}</Typography>
    </TableCell>
    {(viewOrderDialog.stage !== 'rfq' && viewOrderDialog.stage !== 'in-production' && !getType('Receive Order')) && (
      <>
        <TableCell scope="row">
          <Typography className="">{orderDetail.unit_price !== null && '$'}{orderDetail.unit_price}</Typography>
        </TableCell>
        {!hideDiscountField() && <TableCell component="th" scope="row">
          {!isShippingCost(orderDetail) && <Typography className="">
            ${getDiscountAmtFromPercentage(orderDetail?.discount, orderDetail?.unit_price, orderDetail?.quantity)}
          </Typography>}
        </TableCell>}

        <TableCell scope="row">
          <Typography className="">{orderDetail.amount !== null && '$'}{orderDetail.amount}</Typography>
        </TableCell>
        {['invoice'].includes(viewOrderDialog.stage) && isSupplier(currentUser.role) &&
          <TableCell scope="row">
            <Typography>{orderDetail.box_number}</Typography>
          </TableCell>}
      </>
    )}
  </>)

  // TODO This is a temporary fix that need to be removed once invoice response has been added
  const newColumns = (orderDetail, index) => {
    const formValues = getValues()?.order_details?.[index] || {}
    const { isSelected, quantity } = formValues || {}
    // const reasonName = reason?.name || ''
    return (
      <>
        {
          getType('Receive Order') ? (
            <>
              <TableCell scope="row">
                <Typography className="">{orderDetail.name}</Typography>
              </TableCell>
            </>
          ) : (
            <>
            {showCustomerNamefield() && (
              <TableCell scope="row">
                <Typography className="">{orderDetail.customer_name}</Typography>
              </TableCell>
            )}
            {showNamefield() && (
              <TableCell scope="row" sx={{minWidth: "200px", maxWidth: "300px"}} className='break-words'>
                {/* {orderDetail?.name?.length > 40 ? (
                  <Tooltip title={orderDetail.name}>
                    <Typography
                      style={{ whiteSpace: "nowrap" }}
                    >{`${orderDetail?.name?.slice(0, 40)}...`}</Typography>
                  </Tooltip>
                ) : (
                  <Typography style={{ whiteSpace: "nowrap" }}>
                    {orderDetail?.name}
                  </Typography>
                )} */}
                <Typography>
                    {orderDetail?.name}
                </Typography>
              </TableCell>
            )}
            {!showNamefield() && (
              <>
                <TableCell scope="row">
                  <Typography className="">{orderDetail.length}</Typography>
                </TableCell>
                <TableCell scope="row">
                  <Typography className="">{orderDetail.width}</Typography>
                </TableCell>
                <TableCell scope="row">
                  <Typography className="">{orderDetail.height}</Typography>
                </TableCell>
                <TableCell scope="row">
                  <Typography style={{ whiteSpace: "nowrap" }}>
                    {orderDetail.color}
                  </Typography>
                </TableCell>
                <TableCell scope="row">
                  {orderDetail?.type?.length > 30 ? (
                    <Tooltip title={orderDetail.type}>
                      <Typography
                        style={{ whiteSpace: "nowrap" }}
                      >{`${orderDetail?.type?.slice(0, 30)}...`}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      {orderDetail?.type}
                    </Typography>
                  )}
                </TableCell>
                <TableCell scope="row">
                  {orderDetail?.polish?.length > 30 ? (
                    <Tooltip title={orderDetail.polish}>
                      <Typography
                        style={{ whiteSpace: "nowrap" }}
                      >{`${orderDetail?.polish?.slice(0, 30)}...`}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      {orderDetail?.polish}
                    </Typography>
                  )}
                </TableCell>
              </>
              )}
              <TableCell scope="row">
                <Typography className="">{orderDetail.subType}</Typography>
              </TableCell>
              {showOrderTypefield() && (
                <TableCell scope="row">
                  <Typography className="">{orderDetail.order_type}</Typography>
                </TableCell>
              )}
              <TableCell scope="row">
                <Typography className="">{orderDetail.quantity}</Typography>
              </TableCell>
            </>
          )
        }
        {viewOrderDialog.stage !== 'in-production' &&
          <>
            <TableCell scope="row">
              <Typography style={{ whiteSpace: "nowrap" }}>{orderDetail.customer_provider_number}</Typography>
            </TableCell>
            <TableCell scope="row">
              <Typography style={{ whiteSpace: "nowrap" }}>{orderDetail.decedent}</Typography>
            </TableCell>
          </>}
        {
          (!['rfq', 'rfd', 'completed'].includes(viewOrderDialog.stage) || getType('Receive Order')) && (
            <TableCell scope="row">
              <Typography className="whitespace-nowrap">{orderDetail.weight}{orderDetail.weight !== null && ' lbs'}</Typography>
            </TableCell>
          )
        }
        {/* {
        ['quote'].includes(viewOrderDialog.stage) && isCustomer(currentUser.role) && (
          <>
            <TableCell scope="row">
              <Typography className="whitespace-nowrap">{orderDetail.tariff !== null && ' $'}{orderDetail.tariff}</Typography>
            </TableCell>
            <TableCell scope="row">
              <Typography className="whitespace-nowrap">{orderDetail.margin}{orderDetail.margin !== null && ' %'}</Typography>
            </TableCell>
          </>
        )
      } */}
        {!['rfq', 'rfd', 'completed'].includes(viewOrderDialog.stage) && !isVasPrice && <TableCell scope="row">
          <Typography className="">{orderDetail.unit_price !== null && ''}{formatter.format(orderDetail.unit_price)}</Typography>

        </TableCell>}

        {!['rfq', 'rfd', 'completed'].includes(viewOrderDialog.stage) &&
          <TableCell scope="row">
            <Typography className="whitespace-nowrap">{orderDetail.quantity * orderDetail.weight}{orderDetail.quantity && orderDetail.weight ? ' lbs' : ''}</Typography>
          </TableCell>
        }
        {!hideDiscountField() && <TableCell component="th" scope="row">
        <Typography className="">
        {formatter.format(getDiscountAmtFromPercentage(orderDetail?.discount, orderDetail?.unit_price, orderDetail?.quantity))}
        </Typography>
        </TableCell>}
        {['rfq'].includes(viewOrderDialog.stage) && isCustomer(currentUser.role) && <TableCell component="th" scope="row">
          <Typography className="">{orderDetail.comments}</Typography>
        </TableCell>}
        {!['rfq', 'rfd', 'completed'].includes(viewOrderDialog.stage) && <TableCell scope="row">
          <Typography className="">{(orderDetail.amount !== null || isVasPrice) && ''}{formatter.format(isVasPrice ? orderDetail?.vas_price : orderDetail.amount)}</Typography>
        </TableCell>}
        {['in-production', 'fulfilled', 'invoice'].includes(viewOrderDialog.stage) &&
          <TableCell scope="row" sx={{minWidth: "250px", maxWidth: "250px"}} className='break-words'>
            <Typography>{orderDetail.box_number && orderDetail.box_number.replace(/,/g, ', ')}</Typography>
          </TableCell>}
        {
          viewOrderDialog.param?.showAttachment && viewOrderDialog.param?.name !== "Move to Production" &&
          <TableCell scope="row">
            <Tooltip title="Add Documents">
              <FuseSvgIcon
                className="custom-icon"
                size={24}
                onClick={() => {
                  setApprovalDialog({
                    type: 'addAttachments',
                    title: viewOrderDialog.param.is_proof_approval ? 'Proof Approval' : 'Drawing Approval',
                    open: true,
                    attachmentProps: {
                      attachmentsType: `order_details.${index}.${viewOrderDialog.param.is_proof_approval ? 'proof_documents' : 'drawing_documents'}`,
                      newAttachmentsType: `order_details.${index}.${viewOrderDialog.param.is_proof_approval ? 'new_proof_documents' : 'new_drawing_documents'}`,
                      payload: { image_customisation_type: viewOrderDialog.param.is_proof_approval ? 'proof' : 'drawing' },
                    }
                  })
                }}
              >
                material-solid:attach_file
              </FuseSvgIcon>

            </Tooltip>
            <Typography className='text-red'>{attachMessage && getMessage(index, viewOrderDialog.param.is_proof_approval)}</Typography>
          </TableCell>
        }
        {getType('Receive Order') && (
          <>
            <TableCell scope="row">
              <Controller
                control={control}
                name={`order_details.${index}.newQty`}
                rules={{
                  required: {
                    value: true,
                    message: 'Required'
                  },
                  min: {
                    value: 0, // Replace 'min' with the actual minimum prop value
                    message: `Value must be at least ${0}`,
                  },
                  max: {
                    value: quantity, // Replace 'max' with the actual maximum prop value
                    message: `Value cannot exceed ${quantity}`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    // className="mb-24"
                    label=""
                    id="schedule"
                    variant="standard"
                    fullWidth
                    size="small"
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: { width: '60px' }
                    }}
                    sx={{ width: '60px' }}
                    disabled={!isSelected}
                    error={!!errors?.order_details?.[index]?.newQty}
                    helperText={errors?.order_details?.[index]?.newQty?.message}
                    required
                    value={field?.value}
                    onChange={(e) => {
                      //  eslint-disable-next-line
                      setValue(`order_details.${index}.newQty`, parseInt(e?.target?.value))
                      // eslint-disable-next-line
                      if (parseInt(formValues?.quantity) === parseInt(e?.target?.value)) {
                        setValue(`order_details.${index}.notes`, '')
                      }
                      field.onChange(e?.target?.value);
                    }}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <AuraControlledAutocomplete
                // labelName='Reason'
                control={control}
                rules={{
                  required: {
                    // eslint-disable-next-line
                    value: parseInt(formValues?.quantity || 0) !== parseInt(formValues?.newQty || 0),
                    message: 'Required'
                  }
                }}
                controlName={`order_details.${index}.notes`}
                inputValues={reasonOption || []}
                // eslint-disable-next-line
                required={parseInt(formValues?.quantity) !== parseInt(formValues?.newQty)}
                error={isSelected && !!errors?.order_details?.[index]?.notes}
                helperText={isSelected && errors?.order_details?.[index]?.notes?.message}
                className= 'mt-8'
                // className='mt-0 mb-0'
                defaultValue={formValues?.notes}
                // eslint-disable-next-line
                disabled={parseInt(formValues?.quantity || 0) === parseInt(formValues?.newQty || 0)}
                variant="standard"
                InputProps={{
                  sx: { width: '140px' }
                }}
                creatable
                showAdd
                forcePopupIcon
                freeSolo={false}
                textFieldSx={{ width: '140px' }}
              // onUpdate={(e, value) => {
              //   if (value?.name !== 'Others') {
              //     setValue(`order_details.${index}.notes`, '')
              //   }
              // }}
              />
            </TableCell>
            {/* <TableCell scope="row">
              <Controller
                control={control}
                name={`order_details.${index}.note`}
                rules={{
                  required: {
                    value: isSelected && reasonName === 'Others',
                    message: 'Required'
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label=""
                    id="schedule"
                    variant="standard"
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: { width: '140px' }
                    }}
                    sx={{ width: '140px' }}
                    disabled={!isSelected || reasonName !== 'Others'}
                    error={isSelected && reasonName === 'Others' && !!errors?.order_details?.[index]?.note}
                    helperText={isSelected && reasonName === 'Others' && errors?.order_details?.[index]?.note?.message}
                    required={isSelected && reasonName === 'Others'}
                  />
                )}
              />
            </TableCell> */}
          </>
        )}
      </>
    )
  }

  const getDocument = (ind) => {
    const hasDocuments = viewOrderDialog.data.some(item => item.documents);
    const hasVas = viewOrderDialog.data.some(item => item.vas)
    const eachHasDocument = viewOrderDialog.data.every(item => item.documents);
    const eachHasVas = viewOrderDialog.data.every(item => item.vas);
    const packingSlip = viewOrderDialog.data[ind]?.packing_slips
    const supplierInvoices = viewOrderDialog.data[ind]?.supplier_invoices

    if (hasDocuments || hasVas || viewOrderDialog.stage === 'invoice') {
      if (viewOrderDialog.data[ind].documents || viewOrderDialog.data[ind].vas || packingSlip?.length > 0 || supplierInvoices?.length > 0) {
        // setOpen((prev) => ({ [ind]: true }))}
        return <TableCell scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen((prev) => ({ ...prev, [ind]: !prev[ind] }))}
            title="Show VAS"
          >
            <Icon>
              {open[ind] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </IconButton>
        </TableCell>
      }
      return <TableCell />
    }
    if (eachHasVas || eachHasDocument || viewOrderDialog?.stage === 'invoice') {
      if (viewOrderDialog.data[ind].documents || viewOrderDialog.data[ind].vas) {
        return <TableCell scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen((prev) => ({ ...prev, [ind]: !prev[ind] }))}
            title="Show VAS"
          >
            <Icon>
              {open[ind] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </IconButton>
        </TableCell>
      }
    }
    if (getType('Receive Order'))
      return <TableCell><></></TableCell>
    return ""

  }

  const showEmptySupplierAlert = isCommunicationTab && !Array.isArray(list) || list?.length === 0 || list?.every(item => item === null)

  return viewOrderDialog && viewOrderDialog.props && viewOrderDialog.props.open ? (
    <FormProvider {...methods}>
      <AuraBaseDialog
        dialogProps={viewOrderDialog.props}
        closeDialog={closeDialog}
        dialogParam={{
          title: viewOrderDialog.param.title,
          hideDialogActions: !(getType('send to supplier') || getType('create supplier order') || getType('create supplier order')
            || viewOrderDialog?.param?.onSubmit || getType('receive shipment')
          ) || showEmptySupplierAlert,
          maxWidth: viewOrderDialog.param.maxWidth || 'xl',
          submitButtonName: viewOrderDialog.param.submitButtonName,
          type: viewOrderDialog.param.type,
        }}
        disableSubmitButton={disableSubmitButton}
        header={headerData}
        onSubmit={handleSubmit(() => handleFormSubmit())}
        showDialogActions={viewOrderDialog?.param?.showDialogActions ? getComponent() : null}
        additionalButton={additionalButton}
        disableCloseIcon={loading || performingAction}
      >
        {suppliersListLoading || quotedSuppliersListLoading || performingAction || loading ? (
          <Loader />
        ) : (
          <>
            <div className='hidden'><PrintableColumns header={headerData} data={viewOrderDialog.data} ref={printRef} showDiscount={!hideDiscountField()} /></div>
            {isDialogName('viewOrder') && (
              <div className="table-responsive w-full">
                {viewOrderDialog?.param?.title === "Create Supplier Order" && (
                  <div>
                    <Checkbox
                      checked={checked}
                      className='px-8 py-8'
                      onChange={() => { setChecked(!checked) }}
                    />
                    Send for Approval
                  </div>
                )}
                {getType('send to supplier') && (
                  <>
                    {previousSuppliers?.length > 0 &&
                      <div className='mb-10'><Typography variant="h6" className="mt-6">Previously Contacted Suppliers:</Typography>
                        {
                          previousSuppliers?.map((item) => (
                            <Chip className='m-6' label={item.name} variant='outlined' />
                          ))
                        }</div>
                    }
                    {
                      showEmptySupplierAlert ? (
                        <Alert severity="info">{`Selected ${viewOrderDialog.stage} is not associated with any of the Suppliers.`}</Alert>
                      ) : (
                        <>
                          <Typography variant="h6" className="mt-6 mb-6">Select Suppliers:</Typography>
                          <AuraControlledAutocomplete
                            labelName='Suppliers'
                            control={control}
                            controlName='suppliers'
                            inputValues={list || []}
                            onUpdate={(e, value) => {
                              setSelectedSuppliers(value);
                              setIschnaged(true)
                            }}
                            multiple
                            className='mt-6 mb-24 '
                            required
                          /></>
                      )
                    }
                  </>
                )}
                {!isCommunicationTab && <Table className="simple w-full min-w-full">
                  <AuraSimpleTableHead
                    headerList={headers}
                    classes={{ cnCell: 'font-semibold px-8 py-8' }}
                  />
                  <TableBody>
                    {getCols() || viewOrderDialog?.data?.map((orderDetail, index) => {
                      console.log('orderDetail', orderDetail)
                      let proofDocuments = [];
                      let drawingDocuments = [];
                      const documentData = getValues()?.order_details?.[index]
                      const approvalDocuments = documentData?.approval_documents || []
                      const existDocuments = viewOrderDialog.param.is_proof_approval ? documentData?.proof_documents : documentData?.drawing_documents
                      const newDocuments = viewOrderDialog.param.is_proof_approval ? documentData?.new_proof_documents : documentData?.new_drawing_documents

                      let fDocuments = []
                      if (existDocuments?.length > 0) {
                        fDocuments = [...fDocuments, ...existDocuments]
                      }
                      if (newDocuments?.length > 0) {
                        fDocuments = [...fDocuments, ...newDocuments]
                      }
                      if (fDocuments?.length > 0) {
                        fDocuments?.map(d => {
                          if (d.image_customisation_type === 'proof' || viewOrderDialog.param.is_proof_approval)
                            return proofDocuments.push(d)
                          return drawingDocuments.push(d)
                        })
                      }

                      if (!getValues()?.order_details || documentData?.showDocuments) {
                        if (orderDetail?.drawing_documents?.length > 0 && !orderDetail?.proof_documents) {
                          drawingDocuments = orderDetail?.drawing_documents;
                        } else if (orderDetail?.proof_documents?.length > 0 && !orderDetail?.drawing_documents) {
                          proofDocuments = orderDetail?.proof_documents;
                        }
                      }

                      if (approvalDocuments?.length > 0) {
                        drawingDocuments = approvalDocuments
                      }

                      const formValues = getValues()?.order_details?.[index] || {}
                      const { isSelected } = formValues || {}

                      return <Fragment key={index}>
                        <TableRow>
                          {/* {(orderDetail.vas || orderDetail.documents) ? (
                            <TableCell scope="row">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen((prev) => ({ [index]: !prev[index] }))}
                                title="Show VAS"
                              >
                                <Icon>
                                  {open[index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                </Icon>
                              </IconButton>
                            </TableCell>
                          ) : ""} */}
                          {/* {
                            getType('Receive Order') && (
                              <TableCell scope="row">
                                <Checkbox
                                  checked={isSelected}
                                  className='px-8 py-8'
                                  onChange={() => {
                                    setValue(`order_details.${index}.isSelected`, !isSelected)
                                  }}
                                />
                              </TableCell>
                            )
                          } */}
                          {getDocument(index)}
                          <TableCell scope="row">
                            <Typography className="">{index + 1}</Typography>
                          </TableCell>
                          {['invoice', 'in-production'].includes(viewOrderDialog.stage) && <TableCell scope="row">
                            <Typography className="">{orderDetail.order_no}</Typography>
                          </TableCell>}
                          {/* TODO This is a temporary fix that need to be removed once invoice response has been added */}
                          {viewOrderDialog.stage === 'invoice' ? oldColumns(orderDetail) : newColumns(orderDetail, index)}
                        </TableRow>
                        {(orderDetail?.vas?.text?.[0]?.name || orderDetail?.vas?.text?.[0]?.value || orderDetail?.vas?.text?.[0]?.url) && (
                          <TableRow>
                            <TableCell colSpan={8} />
                            <TableCell colSpan={5}>Sandblasting</TableCell>
                            <TableCell >
                              {
                                viewOrderDialog?.param?.showPriceField ? (
                                  <Controller
                                    control={control}
                                    name={`order_details.${index}.vas.textPrice`}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: 'Required'
                                      }
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        label="Price"
                                        id="schedule"
                                        variant="standard"
                                        fullWidth
                                        size="small"
                                        type='number'
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        error={!!errors?.order_details?.[index]?.vas.textPrice}
                                        helperText={errors?.order_details?.[index]?.vas.textPrice?.message}
                                        required
                                      />
                                    )}
                                  />
                                ) : (
                                  <Typography className="">
                                  {orderDetail.vas.textPrice !== null ? formatter.format((orderDetail?.vas?.textPrice)) : '-'}
                                </Typography>
                                
                                )
                              }
                            </TableCell>
                            <TableCell colSpan={2} />
                          </TableRow>
                        )}
                        {(orderDetail?.vas?.images?.[0]?.name || orderDetail?.vas?.images?.[0]?.value || orderDetail?.vas?.images?.[0]?.url) && (
                          <TableRow>
                            <TableCell colSpan={8} />
                            <TableCell colSpan={5}>Etching</TableCell>
                            <TableCell >
                              {
                                viewOrderDialog?.param?.showPriceField ? (
                                  <Controller
                                    control={control}
                                    name={`order_details.${index}.vas.imagePrice`}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: 'Required'
                                      }
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        label="Price"
                                        id="schedule"
                                        variant="standard"
                                        fullWidth
                                        size="small"
                                        type='number'
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        error={!!errors?.order_details?.[index].vas.imagePrice}
                                        helperText={errors?.order_details?.[index].vas.imagePrice?.message}
                                        required
                                      />
                                    )}
                                  />
                                ) : (
                                  <Typography className="">{orderDetail?.vas?.imagePrice !== null ? formatter.format((orderDetail.vas.imagePrice)) : '-'}</Typography>
                                )}
                            </TableCell>
                            <TableCell colSpan={2} />
                          </TableRow>)}

                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                            <Collapse in={Boolean(open[index])} timeout="auto">
                              {drawingDocuments.length ?
                                <>
                                  <Typography variant="h6" className="mt-6 mb-10">
                                    {orderDetail.vas ? 'Proof' : 'Drawing'}
                                  </Typography>
                                  <ImageRenderer attachments={drawingDocuments} handleSelectionMedia={(media) => handleSelectedImages(media, index)} showCheckbox={['Send for Quote Approval', 'Send to Supplier'].includes(viewOrderDialog?.param?.title)} />
                                </> : null
                              }
                              {orderDetail.vas &&
                                <>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    className="mt-6"
                                  >
                                    Value Added Service (VAS)
                                  </Typography>
                                  {!viewOrderDialog?.data?.[0]?.vas?.stoneUrl ? <Vas
                                    type="table"
                                    editable={false}
                                    vas={orderDetail.vas}
                                    open={open}
                                  /> : vasCustomzation()}
                                </>
                              }
                              {proofDocuments.length ?
                                <>
                                  <Typography variant="h6" className="mt-6 mb-10">
                                    Proof
                                  </Typography>
                                  <ImageRenderer attachments={proofDocuments} />
                                </> : null
                              }
                            </Collapse>

                          </TableCell>
                        </TableRow>
                      </Fragment>
                    })}

                    {viewOrderDialog.param?.packingSlips?.length > 0 && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className="mt-6"
                          >
                            Packing Slips
                          </Typography>
                          <ImageRenderer attachments={viewOrderDialog.param?.packingSlips} />
                        </TableCell>
                      </TableRow>
                    )}
                    {viewOrderDialog.param?.supplierInvoices?.length > 0 && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className="mt-6"
                          >
                            Supplier Invoices
                          </Typography>
                          <ImageRenderer attachments={viewOrderDialog.param?.supplierInvoices} />
                        </TableCell>
                      </TableRow>
                    )}

                    {viewOrderDialog?.data?.[0]?.uploadedFile && (
                      <TableCell colSpan={10}>
                        <ImageContent
                          image={image}
                          title='Documents'
                          onChange={() => setMap(map !== 'documents' ? 'documents' : false)}
                          expanded={map === 'documents'}
                        />
                      </TableCell>
                    )}
                  </TableBody>

                </Table>}
                {addNote.includes(viewOrderDialog?.param.name)
                  && (<div className="flex flex-col md:flex-row w-full mt-10">
                    <div className="flex w-full ">
                      <Controller
                        control={control}
                        name="note"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            label="Notes"
                            id="note"
                            variant="outlined"
                            fullWidth
                            size="small"
                            multiline
                            rows={3}
                            error={!!errors.note}
                            helperText={errors?.note?.message}
                          />
                        )}
                      />

                    </div>
                  </div>)
                }

              </div>
            )}
          </>
        )}
      </AuraBaseDialog >
      <ApprovalContent
        {...approvalDialog}
        onClose={() => setApprovalDialog({ open: false })}
        heading='Add Documents for Approval'
        hideDialogActions
      />
    </FormProvider >
  ) : (
    <></>
  );
}
