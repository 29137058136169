import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { openDialog, closeDialog as fuseCloseDialog } from "app/store/fuse/dialogSlice";
import { useReactToPrint } from 'react-to-print';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateCustomer } from 'app/main/apps/client/store/customerSlice';
import { closeViewOrderDialog, confirmInvoice, updateInvoice } from 'app/main/dashboards/main/store/widgetsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { checkNullOrUndefined, isSupplier } from 'app/main/apps/common/AuraFunctions';
import { editShipment } from 'app/main/apps/backoffice/store/shipmentOrderSlice';
import { checkUniqueOrderNo } from 'app/main/apps/client/store/employeeSlice';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import AuraBaseDialog from '../../../common/AuraBaseDialog';
import OrderHeader from '../../../common/OrderHeader';
import { getOrderStatuses, getOrders } from '../../store/ordersSlice';
import { updateAddress } from '../../../settings/store/addressSlice';
import StatusFormContent from './StatusFormContent';
import {
  closeOrderDialog,
  getOrderDetails,
  resetOrder,
  performAction,
  updateOrder,
  newOrder,
  updateShipmentApproval,
  closeViewOrderDialog as orderCloseViewOrderDialog,
  addAdvancePayment
} from '../../store/orderSlice';
import NoteFormContent from './NoteFormContent';
import AddressFormContent from '../../../common/FormContent/AddressFormContent';
import getInitValues, { getSchema } from './OrderBase';
import PaymentFormContent from '../../../common/FormContent/PaymentFormContent';
import { getCategoryValues } from '../../../settings/store/configurationsSlice';
import InvoiceTab from '../tabs/InvoiceTab';
import DiscountFormContent from './DiscountFormContent';
import ContainerNumberForm from './ContainerNumberForm';
import ShippingLabelContent from './ShippingLabelContent';
import CompleteShipmentFormContent from './CompleteShipmentFormContent';
import ConfirmInvoice from './ConfirmInvoice';
import UpdateInvoice from './UpdateInvoice';
import GenerateInvoice from './GenerateInvoice';
import QuickOrderEdit from './QuickOrderEdit';


const ComponentToPrint = forwardRef((props, ref) => {
  return (<div ref={ref}>
    <InvoiceTab {...props} />
  </div>)
});

const PrintShippingLabelContent = forwardRef((props, ref) => {
  return (<div ref={ref}>
    <ShippingLabelContent {...props} />
  </div>)
});

function OrderDialog(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ user }) => user);
  const orderDialog = useSelector(({ mainDashboardApp, eCommerceApp }) => {
    if (props.isDashboard) {
      return mainDashboardApp?.widgets?.orderDialog;
    }
    return eCommerceApp?.order?.orderDialog;
  });
  const { completeOrderData, data: paymentData } = orderDialog;
  const updateStatusValues = useSelector(({ eCommerceApp }) => eCommerceApp?.orders?.updateStatusValues);
  const [options, setOptions] = useState([]);
  const tempSchema = getSchema();
  const [schema, setSchema] = useState(tempSchema);
  const [orderTypes, setOrderTypes] = useState([]);
  const [isUniqueOrderNo, setIsUniqueOrderNo] = useState(false)
  const methods = useForm({
    mode: "onChange",
    defaultValues: getInitValues("payment", paymentData),
    resolver: yupResolver(schema),
  });
  const { reset, formState, setError, setValue } = methods;
  const { dirtyFields, isValid } = formState;
  const [dialogTitle, setDialogTitle] = useState('Order');
  const dialogParam = orderDialog?.param;
  const contetRef = useRef()
  const labelRef = useRef()
  const [loading, setLoading] = useState(false)

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    const currentSchema = getSchema(dialogParam.name);
    setSchema(currentSchema);
    if (orderDialog?.data) {
      const dialogName = isAdvancePaymentDialog() ? "advance_payment" : dialogParam.name;
      const data = getInitValues(dialogName, paymentData);
      reset({ ...data });
      if (isDialogType('edit_order')) {
        dispatch(getOrderStatuses({ params: { c: 'order_type' } }))
          .unwrap()
          .then((res) => {
            setOrderTypes(res.data);
            const orderTypeName = data?.order_type?.name;
            if (orderTypeName) {
              const defaultValue = res.data.find(d => d.name === orderTypeName)
              if (defaultValue)
                setValue('order_type', defaultValue)
            }

          });
      }
    }
    if (dialogParam) {
      const title = dialogParam.title || `Update ${dialogParam.name}`;
      setDialogTitle(title);
    }
    if (isDialogType('payment') || isDialogType('complete_shipment')) {
      dispatch(
        getCategoryValues({
          params: { c: isDialogType('payment') ? 'payment_type' : 'shipping_company' },
        })
      )
        .unwrap()
        .then((response) => {
          const statusesTemp = response && response.data ? response.data : [];
          setOptions(statusesTemp);
        });
    }
    if (isDialogType('review_note')) {
      reset()
    }
  }, [dialogParam, dispatch, orderDialog?.data, reset]);

  const validateOrderNo = useCallback(
    // TODO Question - checkUnique is validationg email in user table. What if the user uses email that is in customer table not in user table? (customer login is not created)
    debounce((event, value) => {
      dispatch(checkUniqueOrderNo({ orderNo: value, id: orderDialog?.data?.id, }))
        .unwrap()
        .then((response) => {
          setIsUniqueOrderNo(Boolean(response.errors));
        });
    }, 500),
    [orderDialog]
  );

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (orderDialog?.props.open) {
      initDialog();
    }
  }, [orderDialog?.props.open, initDialog]);

  /**
   * Close Dialog
   */
  function closeDialog() {
    setLoading(false)
    if (orderDialog?.dashboard) {
      dispatch(
        closeViewOrderDialog({
          field: 'orderDialog',
        })
      );
    } else {
      dispatch(closeOrderDialog());
    }
    dispatch(resetOrder())
  }

  function isDialogType(name) {
    return dialogParam && dialogParam.name === name;
  }

  function isAdvancePaymentDialog() {
    return dialogParam && dialogParam.paymentType && dialogParam.name === 'payment' && dialogParam.paymentType === 'advance';
  }

  function getSuccessMessage() {
    return dialogParam.successMessage
      ? dialogParam.successMessage
      : 'Order has been updated successfully';
  }

  const handleDownload = useReactToPrint({
    content: () => contetRef.current,
    pageStyle: '',
    documentTitle: `invoice.pdf`,
  });

  const handleLabelDownload = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: '',
    documentTitle: `shipping-label.pdf`,
  });

  const handlePerformAction = (payload) => {
    dispatch(performAction(payload))
      .unwrap()
      .then(() => {
        if (dialogParam.loadOrdersFun) {
          dialogParam.loadOrdersFun();
        }
        dispatch(
          showMessage({
            message: getSuccessMessage(),
            type: 'success',
          })
        );
      });
  }

  /**
   * handle on submit of Mark as Completed and Shipped With Duplicate Invoice number
   */
  const proceedWithDuplicateInvoice = (payload) => {
    // Set forced_update_invoice to true to accept  duplicate invoice number
    const payloadData = payload.data;
    payloadData.forced_update_invoice = true;
    handleCompleteShipmentSubmit(payload);
  }


  /**
   * handle on submit of Mark as Completed and Shipped
   */
  const handleCompleteShipmentSubmit = (payload) => {
    dispatch(performAction(payload))
      .unwrap()
      .then((res) => {
        // If Invoice Number already exists backend sends 200 renponse with Error object
        if (_.has(res, "Error")) {
          setLoading(false);
          // handled only Invoice Already exists response
          if (res.Error === "Invoice Already exists") {
            // Open Confirmation message to proceed with duplicate invoice number
            // If user confirms set forced_update_invoice flag
            // If user does not confirm stay in the same dialog
            dispatch(
              openDialog({
                children: (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      Duplicate Invoice Number
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Invoice number already exists. Do you want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          dispatch(fuseCloseDialog())
                        }}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          setLoading(true);
                          dispatch(fuseCloseDialog());
                          proceedWithDuplicateInvoice(payload);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </>
                ),
              })
            );
          }
        }
        if (!_.has(res, "Error")) {
          // If response does not have error close dialog and proceed
          closeDialog();
          if (dialogParam.loadOrdersFun) {
            dialogParam.loadOrdersFun();
          }
          dispatch(
            showMessage({
              message: getSuccessMessage(),
              type: "success",
            })
          );
        }
      });
  }

  /**
   * Form Submit
   */
  function onSubmit(data) {
    if (isDialogType('invoice')) {
      handleDownload()
    }
    else if (isDialogType('shipping label')) {
      handleLabelDownload()
    }
    else if (isDialogType('rejectQuote')) {
      dialogParam?.onSubmit(data)
    }
    else if (isDialogType('address')) {
      if (data.address.id) {
        dispatch(updateAddress(data.address)).then(() => {
          dispatch(getOrderDetails({ id: orderDialog.data.id, params: {} }));
        });
      } else {
        const inputData = {
          id: data.cid,
          address_id: data.address_id,
          address: data.address,
          updateAddress: 'yes',
        };
        dispatch(updateCustomer(inputData)).then(() => {
          dispatch(getOrderDetails({ id: orderDialog.data.id, params: {} }));
        });
      }
    } else if (isDialogType('payment')) {
      if (isAdvancePaymentDialog()) {
        const payload = { ...data }
        delete payload.data;
        dispatch(addAdvancePayment(payload))
          .unwrap()
          .then(() => {
            dispatch(
              showMessage({
                message: 'Advance payment added successfully!',
                type: 'success',
              })
            );
          });
      } else {
        const payload = {
          ui_id: "MAKE_PAYMENT",
          data
        }
        delete payload.data?.data
        handlePerformAction(payload)
      }
    }
    else if (isDialogType('discount')) {
      const { discount, ...rest } = data
      const payload = {
        ui_id: "DISCOUNT",
        data: {
          order_ids: paymentData.order_ids,
          discount: +discount
        }
      }
      handlePerformAction(payload)
    }
    else if (isDialogType('confirm_invoice')) {
      const param = {
        shipping_cost: data.shipping_cost,
        tax: data.tax,
        comments_to_send: data.comments_to_send
      }
      dispatch(confirmInvoice({ id: data.id, param })).unwrap().then(() => {
        dialogParam.loadOrdersFun()
        dispatch(
          showMessage({
            message: getSuccessMessage(),
            variant: 'info',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
          })
        );
      })
    }
    else if (isDialogType('update_invoice')) {
      const param = {
        shipping_cost: data.shipping_cost,
        tax: data.tax
      }
      dispatch(updateInvoice({ id: data.id, param })).unwrap().then(() => {
        dialogParam.loadOrdersFun()
        dispatch(
          showMessage({
            message: getSuccessMessage(),
            variant: 'info',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
          })
        );
      })
    }
    else if (isDialogType('complete_shipment')) {
      setLoading(true);
      const payload = {
        ui_id: "COMPLETE_AND_SHIP",
        data: {
          ...data,
          packing_slips: data?.packing_slips && data?.packing_slips?.map((item) => ({ id: item.id })) || [],
          supplier_invoice: data?.supplier_invoice && data?.supplier_invoice?.map((item) => ({ id: item.id })) || []
        }
      }
      handleCompleteShipmentSubmit(payload)
    }
    else if (isDialogType('generate_invoice')) {
      const payload = {
        "ui_id": "GENERATE_INVOICE",
        data: {
          shipment_id: data.id,
          invoice_number: data.invoice_number
        }
      }
      handlePerformAction(payload)
    }
    else if (isDialogType('container_number')) {
      // Added condition to avoid backend error
      if (data && data.shipment_id && !checkNullOrUndefined(data.shipment_id)) {
        dispatch(editShipment(data)).unwrap().then((res) => {
          if (res?.message === 'Container Number exists') {
            setError('container_number', { message: res?.message })
          } else {
            const params = { stage: orderDialog.stage }
            if (dialogParam.loadOrdersFun) {
              dialogParam.loadOrdersFun();
            } else {
              dispatch(getOrders({ params }));
            }
            dispatch(
              showMessage({
                message: getSuccessMessage(),
                type: 'success',
              })
            );
            closeDialog()
          }
        });
      }
      return
    }
    else {
      if (isDialogType('status')) {
        data.updateStatus = 'yes';
      }
      if (isDialogType('confirm')) {
        data.confirmOrder = 'yes';
      }
      if (isDialogType('note')) {
        data.updateNote = 'yes';
      }
      if (isDialogType('review_note')) {
        data.updateNote = 'yes';
        data.id = completeOrderData?.id
        data.note_list = [data?.note]
        if (data?.isInternalNotes) {
          data.note_list.push(`[INTERNAL] - ${data.internalNote}`)
        }
        if (dialogParam?.title !== 'Mark as Completed') {
          delete data?.note
        }
        delete data?.internalNote
        delete data?.isInternalNotes
      }
      if (isDialogType('approveActions')) {
        const isApproval = ['REJECT_DRAWING', 'APPROVE_DRAWING',
          'SEND_FOR_DRAWING_APPROVAL', 'SEND_FOR_PROOF_APPROVAL', 'REQUEST_REVISION_FOR_PROOF', 'APPROVE_PROOF', 'REJECT', 'APPROVE_VAS_PRICE', 'SEND_PRICE_APPROVAL_FOR_VAS'].includes(dialogParam?.data?.uiId)
        const isRejectQuote = ['REJECT', 'REJECT_DRAWING', 'REJECT_QUOTE', 'REQUEST_REVISION_FOR_VAS_PRICE', 'REQUEST_REVISION_FOR_DRAWING'].includes(dialogParam?.data?.uiId)
        if (isRejectQuote) {
          if (!data.note_type && !['Request Quote Revision', 'Request Revision for Vas price', 'Request Revision for Order', 'Request Revision for Drawing'].includes(orderDialog?.param?.title)) {
            setError('note_type', { message: 'Required' })
            return
          }
          if (!data.note && (data.note_type?.name === 'Others' || ['Request Quote Revision', 'Request Revision for Vas price', 'Request Revision for Drawing', 'Request Revision for Order'].includes(orderDialog?.param?.title))) {
            setError('note', { message: 'Required' })
            return
          }
        }
        if (!data.note && ['Request Revision for Drawing', 'Reject Shipment'].includes(orderDialog?.param?.title)) {
          setError('note', { message: 'Required' })
          return
        }

        data = {
          id: data.id,
          note: data.note ? data.note : data.note_type?.name,
          to_statuses_type_id: dialogParam?.data?.toStatusCode,
          [isApproval ? 'update_status_with_attachment' : 'updateStatus']: 'yes'
        }
        if (!data.note && (['REJECT_DRAWING', 'REJECT', 'REQUEST_REVISION_FOR_PROOF', 'REQUEST_REVISION_FOR_ORDER'].includes(dialogParam?.data?.uiId) || dialogParam?.data?.
          toStatusCode === "Send for Review")) {
          setError('note', { message: 'Required' })
          return
        }
      }
      if (dialogParam.completeParam) {
        data.is_complete = true
        data.entity_history_id = dialogParam.entity_history_id
      }
      if (['Reject Shipment', 'Approve Shipment'].includes(orderDialog?.param?.title)) {
        // Added condition to avoid backend error undefined
        if (orderDialog && orderDialog?.data && !checkNullOrUndefined(orderDialog?.data?.shipment_id)) {
          const isApproval = orderDialog?.param?.title === 'Approve Shipment';
          dispatch(updateShipmentApproval({ id: orderDialog?.data?.shipment_id, note: data.note, is_approve: isApproval })).then(() => {
            if (dialogParam.loadOrdersFun) {
              dialogParam.loadOrdersFun();
            }
          })
        }
      } else {
        dispatch(updateOrder(data)).unwrap().then(() => {
          dispatch(
            showMessage({
              message: getSuccessMessage(),
              variant: 'info',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
          );
          dispatch(newOrder());
          if (dialogParam.loadOrders === 'yes') {
            dispatch(getOrders());
          }
          if (dialogParam.loadOrdersFun) {
            dialogParam.loadOrdersFun();
          }
        });
      }
    }
    if (isDialogType('approveActions')) {
      if (orderDialog?.dashboard) {
        dispatch(closeViewOrderDialog())
      }
      else dispatch(orderCloseViewOrderDialog())
    }
    if (!isDialogType("complete_shipment")) {
      closeDialog();
    }
  }

  function disableSubmitButton() {
    if (isDialogType('invoice') || isDialogType('shipping label') || isDialogType('confirm') || isDialogType('approveActions') || isDialogType('review_note')) return false
    if (isDialogType('complete_shipment')) {
      return loading || !!(_.isEmpty(dirtyFields) || !isValid)
    }
    return !!(_.isEmpty(dirtyFields) || !isValid);
  }

  const headerData = {
    enable: !isDialogType('approveActions'),
    component: OrderHeader,
    data: {
      order: completeOrderData,
      hideAmount: orderDialog.stage === 'invoice' || isAdvancePaymentDialog(),
      hideQuantity: orderDialog.stage === 'invoice' || isAdvancePaymentDialog(),
      hideCustomer: ['shipment', 'in-production'].includes(orderDialog.stage) || isSupplier(currentUser.role) && orderDialog.stage === 'invoice' || (orderDialog?.param?.name === 'payment' && (['customer', 'supplier'].includes(currentUser.role)) || (orderDialog.stage !== 'invoice') || (dialogParam?.isSupplierInvoice)) || orderDialog?.param?.hideCustomer,
      stage: orderDialog.stage,
      hideShipmentDetails: isDialogType('complete_shipment'),
      showTotalWeight: ['order', 'quote', 'approval', 'shipment', 'in-production'].includes(orderDialog.stage) && !isAdvancePaymentDialog(),
      showSupplierName: (orderDialog?.param?.name === 'payment' && dialogParam.isSupplierInvoice && !isAdvancePaymentDialog()) || orderDialog?.param?.showSupplierName,
      reviewNoteDialog: isDialogType('review_note')
    },
  };

  const getSubmitButtonName = () => {
    if (dialogParam?.submitButtonName)
      return dialogParam.submitButtonName
    return isDialogType('invoice') ? 'Download' : ''
  }

  return orderDialog && orderDialog.props && orderDialog.props.open ? (
    <>
      <FormProvider {...methods}>
        <AuraBaseDialog
          dialogProps={{ open: orderDialog.props.open, fullScreen: isDialogType('invoice') }}
          closeDialog={closeDialog}
          dialogParam={{
            title: dialogTitle,
            submitButtonName: getSubmitButtonName(),
            maxWidth: dialogParam.maxWidth,
            hideDialogActions: dialogParam?.hideDialogActions
          }}
          onSubmit={onSubmit}
          disableSubmitButton={disableSubmitButton}
          header={headerData}
          additionalButton={dialogParam?.additionalButton}
          disableCloseIcon={loading}
          paperClass={isDialogType('edit_order') ? 'overflow-y-visible' : ''}
        >
          {loading ? (<Loader />) : (
            <>
              {isDialogType('status') && <StatusFormContent statuses={updateStatusValues} />}
              {(isDialogType('note') || isDialogType('review_note') || isDialogType('confirm') || isDialogType('rejectQuote') || isDialogType('approveActions')) && (
                <NoteFormContent confirmOrder={isDialogType('confirm')} title={orderDialog?.param?.title} uiId={dialogParam?.data?.uiId} orderDialog={orderDialog} />
              )}
              {isDialogType('address') && <AddressFormContent addressLine1Required />}
              {isDialogType('payment') && <PaymentFormContent paymentTypes={options} isAdvancePayment={isAdvancePaymentDialog()} />}
              {isDialogType('invoice') && <ComponentToPrint ref={contetRef} type='dialog' id={paymentData.id} />}
              {isDialogType('discount') && <DiscountFormContent type='dialog' />}
              {isDialogType('container_number') && <ContainerNumberForm type='dialog' />}
              {isDialogType('shipping label') && <PrintShippingLabelContent ref={labelRef} data={orderDialog.param.data} />}
              {isDialogType('complete_shipment') && <CompleteShipmentFormContent options={options} />}
              {isDialogType('confirm_invoice') && <ConfirmInvoice />}
              {isDialogType('update_invoice') && <UpdateInvoice />}
              {isDialogType('generate_invoice') && <GenerateInvoice />}
              {isDialogType('edit_order') && <QuickOrderEdit validateOrderNo={validateOrderNo} isUniqueOrderNo={isUniqueOrderNo} orderTypes={orderTypes} data={orderDialog?.data} />}
            </>
          )}
        </AuraBaseDialog>
      </FormProvider>
    </>
  ) : (
    <></>
  );
}

export default OrderDialog;